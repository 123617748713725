/**
 * Header overrides for STWC
 */

// override the logo colour
header#new-homepage-test-header,
header#simple-header {
	div.logo {

		path, polygon, rect {
			fill: #BE2A77;
		}

		@include media-breakpoint-down(sm) {
			a {
				background: url('/images/uk/en/homepage/logos/bbc-logo-square.svg');
			}
		}

		@include media-breakpoint-up(md) {
		}
	}

	&.is-sticky {
		.desktop-search-panel{
			.open{
				background-color: #d64591;
			}
		}
		div.logo {
			@include media-breakpoint-down(sm) {
				a {
					background: url("/images/uk/en/homepage/logos/bbc-logo-square.svg");
				}
			}
		}
	}

	&.expanded {
		div.logo {
			@include media-breakpoint-down(sm) {
				a {
					background: url("/images/uk/en/homepage/logos/bbc-logo-square.svg");
				}
			}
		}
	}

	#main-navigation ul li.flipper-container .back-panel .logo {
		background: url('/images/uk/en/homepage/logos/bbc-logo-square.svg');
	}
}