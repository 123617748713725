section{
    &.recommended{
        .window {
            li{
                a{
                    .image{
                        background:#e9e9e9;
                        &:after{
                            background: #e9e9e9;
                        }
                    } 
                    &:hover{
                        .image{
                            background: radial-gradient(ellipse at center, #ffffff 0, #ffffff 100%);
                            &::after{
                                background: radial-gradient(ellipse at center, #ffffff 0, #ffffff 100%);
                            }
                        }
                    }
                    .rating {
                        i.fa-star {
                            color: #333;
                        }
                    }
                }
            }
        }   
    }
}

section.recommended .scroll {
    border-color: solid #666;
}

section.recommended {
    .view-all {
        @include media-breakpoint-down(md){
            padding-left: 5px;
        }
        a {
            color: #333333;
        }
        .arrow.right {
            border-color: #333333;
            @include media-breakpoint-up(lg){
                right: 302px !important;
            }
        }
    }
    .arrow.right {
        @include media-breakpoint-up(lg){
            right: 302px !important;
        }
        @include media-breakpoint-down(md){
            right: 177px;
        }
        @include media-breakpoint-down(sm) {
             right: 35px;
         }
         @media (max-width: 320px) {
             right: 16px;
         }
         @media (min-width: 410px) and (max-width:767px){
             right: 57px;
         }
    }
}   