@import "~dw-uk-bootstrap/scss/_functions.scss";
@import "~dw-uk-bootstrap/scss/_mixins.scss";
@import "~dw-uk-bootstrap/scss/themes/_bbc.scss";

$header-primary: #be2a78;
$header-secondary: $header-primary;
$light-button-border: 2px solid lighten(desaturate(adjust-hue($header-primary, -3), 23.87), 29.22);
$quicklinks: #541d7b;
$recommended-background: #dddddd;
$recommended-cta: $header-primary;
$recommended-cta-hover:#a41369;
$footer-background: #dddddd;
$footer-color: #383838;
$footer-phone: dark;
$social-links-background: #f8f8f8;
$social-icon-color: #666666;
$legal-background: #185f5f;
$empty-cell-grey: #ccc;

@import "./base.scss";
@import './components/gift-cards/gift-cards.scss';
@import './components/gift-cards/e-vouchers/e-vouchers.scss';
@import './components/**/*.bbc.scss';

body.christmas .push-container.header {
	& > div.bootstrap-4:not(header) {
		// stars on the headings
		.mixed-case-highlights h2, .recommended h2, .media-content h2, .newsletter h2 {
			&:before, &:after {
				@include media-breakpoint-down(sm){
					height: 30px;
					width: 30px;
					background-size: 30px 30px;
				}
			}

			&:before {
				@include media-breakpoint-down(sm){
					margin-left: -40px;
				}
			}

			&:after {
				margin-left: 5x;
			}
		}
	}
	section.footer-links {
		background-image: url('/images/uk/en/law/offers/christmas/Homepage_Christmas_White_Footer-xs.png');
		@include media-breakpoint-up(sm) {
			background-image: url('/images/uk/en/law/offers/christmas/Homepage_Christmas_White_Footer.png');
		}
	}
}
section.media-content {
    h2 {
		@include media-breakpoint-down(sm){
			@include font-heading(1.4em);
		}
	}
}
section.recommended {
    h2 {
		@include media-breakpoint-down(sm){
			@include font-heading(1.4em);
		}
	}
}
section.giftbanner {
	margin-bottom: 2em;
}

@media (min-width: 768px) {
    header#new-homepage-test-header {
        .masthead {
            svg {
                path {
                    fill: $white;
                }
            }
        }
	}
}

.masthead {
	#cart {
		svg path,
		svg polygon {
			// white for mobile
			@include media-breakpoint-down(sm) {
				fill: #333333 !important;
			}
			// grey for desktop
			@include media-breakpoint-up(md) {
				fill: #474747 !important;
			}
		}
	}
}

#algolia-autocomplete{
	.aa-SubmitIcon{
		path{
			fill: #000000!important;
		}
	}
}